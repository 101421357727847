var postsToLoadEachTime = 3;

document.addEventListener('DOMContentLoaded', commonInit);
 
function commonInit()
{
	setupMobileMenu();
	setupImageNoise(); 
	setupAccordion();
	setupTabset(); 
    setupFancyfocus();
    loadMorePosts();
    setupVideoPlay();
} 

function setupMobileMenu()
{
	jQuery('#hamburger-container').on('click', function(){
		jQuery('html').toggleClass('menu-open');
	});
}

function setupImageNoise()
{
	let noiseElements = document.getElementsByClassName('noise');

	for(let i=0; i<noiseElements.length; i++)
	{
		let ctx = noiseElements[i].getContext('2d');

		setInterval(function(){
			generateNoise(ctx);
		}, 75);
	} 
	
	resizeNoiseImages(noiseElements);
	
	window.addEventListener('resize', function(){
		resizeNoiseImages(noiseElements);
	});
}

function resizeNoiseImages(noiseElements)
{
	for(let i=0; i<noiseElements.length; i++)
	{
	    noiseElements[i].width = noiseElements[i].parentElement.clientWidth;
	    noiseElements[i].height = noiseElements[i].parentElement.clientHeight;
    }
}

function generateNoise(ctx)
{
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        idata = ctx.createImageData(w, h),
        buffer32 = new Uint32Array(idata.data.buffer),
        len = buffer32.length,
        i = 0;

    for(; i < len;)
        buffer32[i++] = ((255 * Math.random())|0) << 24;
    
    ctx.putImageData(idata, 0, 0);
}

function setupAccordion(){
	
	jQuery(".accordion > .header").click(function(){
		
		var selectedHeader = jQuery(this);
		var closeOnly = false;
		
		if(selectedHeader.hasClass("open"))
			closeOnly = true;
		
		var parent = selectedHeader.parent();
		var previousSelectedHeader = jQuery(".open", parent);	
		
		jQuery(" + div.content", previousSelectedHeader).removeClass("active").slideToggle(300, function(){
			
			jQuery('html, body').animate({scrollTop: selectedHeader.offset().top - jQuery('#desktop-header').height()}, "fast");
			
		});
		
		previousSelectedHeader.removeClass("open");

		if(!closeOnly){
			selectedHeader.toggleClass("open");
			jQuery(" + .content", this).addClass("active").slideToggle(300);
		}
		
	});
	
}

function setupTabset(){
	
	if(jQuery('body').attr('id') == 'template-faq'){
	
		jQuery('.tabs').each(function(){
			
			var firstMatch = jQuery(".tab-menu > li:eq(0)", this);
			var firstClass = firstMatch.attr("data-class-match");
			
			firstMatch.addClass("active").show();
			jQuery('.tab-content > div.'+firstClass, this).addClass("active").show();
			
		});
	
	}else{
	
		jQuery(".tab-menu > li:eq(0), .tab-content > div:eq(0)").addClass("active").show();	
		
	}
	
	jQuery(".tab-menu > li").click(function(){
		
		var menuItem = jQuery(this);
		
		menuItem.siblings(".active").removeClass("active");
		menuItem.addClass("active");
		
		var index = menuItem.index();
		var parent = menuItem.parents(".tabs");
		var classMatch = menuItem.attr("data-class-match");
		var label = menuItem.html();
		
		if(classMatch)
		{
			jQuery(".content", parent).removeClass("active");
			jQuery("."+classMatch, parent).addClass("active");
		}
		else
		{
			jQuery(".tab-content > .active", parent).removeClass("active");
			jQuery(".tab-content > div:eq("+index+")", parent).addClass("active");
		}
		
		jQuery('p.label', parent).html(label);

		adjustTabMenuSlant(jQuery(this).parents('.tab-menu-container'));
		
		if(jQuery(window).width() <= 1200)
			jQuery('.dropdown-container', parent).slideToggle(300);
			
		history.replaceState(null, "", "?tab="+index);
		
		adjustTabHeights();
		
	});
	
	jQuery('.tabs p.label').on('click', function(){
		
		var parent = jQuery(this).parents(".tabs");
		parent.toggleClass('open');
		
	});
	
	jQuery('.tab-menu-container p').on('click', function(){
		jQuery('+ .dropdown-container', jQuery(this)).slideToggle(300);
	});
	
	jQuery(window).on('resize', function(){
		adjustAllMenuSlants();
	});
	
	adjustAllMenuSlants();
	
	var tab = getUrlVars()["tab"];

	if(tab)
		jQuery(".tab-menu > li:eq("+tab+")").trigger('click');
	
	if(jQuery('body').attr('id') == 'template-faq') return false;
	
	adjustTabHeights();
	
	jQuery(window).on('resize', function(){
		adjustTabHeights();
	});

}

function adjustTabHeights()
{
	if(jQuery('#template-lineup').length > 0 || jQuery('#template-home').length > 0){
	
		jQuery('.tab-content').each(function(){
			let currentHeight = jQuery('> div.active', jQuery(this)).outerHeight();
			jQuery(this).height(currentHeight + 40);
		});
		
	}
}

function adjustAllMenuSlants()
{
	jQuery('.tab-menu-container').each(function(){
		adjustTabMenuSlant(jQuery(this));
	});
}

function adjustTabMenuSlant(container)
{
	let slant = jQuery('.slant', container);
	let activeMenu = jQuery('.tab-menu .active', container);
	
	let activeMenuIndex = activeMenu.index();
	let activeMenuLeft = activeMenu.offset().left;
	let activeMenuWidth = activeMenu.outerWidth();
	
	let containerWidth = container.width();

	if(activeMenuIndex === 0)
		slant.css('transform', 'translateX(0) skewX(-15deg) scaleX(' + (activeMenuWidth + activeMenuLeft)/containerWidth + ')');
	else
		slant.css('transform', 'translateX(' + (activeMenuLeft + containerWidth * .01) + 'px) translateX(1%) skewX(-15deg) scaleX(' + activeMenuWidth/containerWidth + ')');
}

function setupFancyfocus()
{
	let formElements = jQuery('input, textarea');
	
	formElements.on('focus', function(){
		jQuery(this).closest("li").addClass('focus');
	});
	
	formElements.on('blur', function()
	{
		let listItem = jQuery(this);
		
		if (!listItem.val()) 
			listItem.closest("li").removeClass('focus');
	});
}

function loadMorePosts(){
	
	var offset = postsToLoadEachTime;
	
	jQuery('#load-more-button').click(function(){
		
		var element = jQuery(this);
		
		element.addClass("loading");
		
		jQuery.ajax({
			url:"/wp-admin/admin-ajax.php",
			type:'POST',
			data:'action=load_more_posts&offset=' + offset + '&count=' + postsToLoadEachTime,
			success:function(results){
				
				if(jQuery.trim(results) != "")
					jQuery('#load-button').before(results);
				else
					jQuery('#load-more-container').html("Sorry, there are no more posts.");
				
				element.removeClass("loading");
				
				offset += postsToLoadEachTime;
				
			}
		});
		
		return false;
			
	});

}

function setupVideoPlay()
{
	jQuery('.content-type.video').on('click', function(){
		
		let thumbnail = jQuery('.thumbnail', this);
		
		thumbnail.fadeOut(300);
		
		jQuery('+ video', thumbnail)[0].play();
		
	});
}

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}
